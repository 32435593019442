<template>
  <v-container class="fidelity-page">
    <CategoryTitle
      :category="category"
      :showOnMobile="true"
      :add-container="false"
    />
    <img class="qr-code-img" :src="imgsrc" alt="Fidelity QR code" />
  </v-container>
</template>
<style lang="scss">
.fidelity-page {
  label {
    font-weight: 600;
    text-transform: uppercase;
  }
  .img-wraper {
    img {
      max-width: 100%;
      border-radius: 8px;
    }
  }
}
</style>
<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import categoryMixins from "~/mixins/category";
import { mapActions } from "vuex";

import UserService from "~/service/userService";

export default {
  name: "Fidelity",
  components: {
    CategoryTitle
  },
  mixins: [categoryMixins],
  data() {
    return {
      user: null,
      fidelityCard: null,
      cardCode: null,
      response: {}
    };
  },
  computed: {
    imgsrc() {
      return `https://api.qrserver.com/v1/create-qr-code/?size=200x200&data=${
        this.user?.fidelityCard != "" ? this.user?.fidelityCard : "no-fidelity"
      }`;
    }
  },
  methods: {
    ...mapActions({
      getCart: "cart/getCart"
    }),
    async getUserData() {
      const result = await UserService.getUserDetail();
      if (result) {
        this.user = result;
      }
    }
  },
  async mounted() {
    await this.getUserData();
  }
};
</script>
